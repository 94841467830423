<template>
  <div>
    <el-button size="small" type="primary" @click="backList">返回列表页</el-button>
    <!--<el-card shadow="never" style="margin-top: 10px;height: 950px" class="diycard">-->
    <el-card shadow="never" style="margin-top: 10px;" class="diycard">
      <div style="font-size: 12px;display:flex;align-items: center;">
        <span>
          商户类型
          <el-popover
              placement="top-start"
              title="商户类型"
              width="450"
              trigger="hover">
              <span>如商户无营业执照,请选择小微进件</span>
              <i style="margin-left: 0px;" class="el-icon-question" slot="reference"></i>
            </el-popover>
        </span>
        <el-radio-group v-model="pieceForm.incomType" size="mini" style="margin-left: 20px;">
          <el-radio-button :label="0">个体户/企业</el-radio-button>
          <el-radio-button :label="1">小微</el-radio-button>
        </el-radio-group>
      </div>
      <div style="margin-top: 40px;">
        <!--    营业执照信息    -->
        <div>
          <el-form ref="pieceForm" :rules="pieceFormRules" :model="pieceForm" label-position="left" label-width="120px">
            <div>
              <span style="font-size: 12px;font-weight: 600">资质信息</span>
              <el-divider/>
              <el-form-item v-if="pieceForm.incomType === 0" prop="license" label="营业执照"
                            style="display: flex;align-items: center;" class="dyIn">
                <el-upload
                    :limit="1"
                    :headers="{'Authorization': this.$store.getters.token}"
                    action="/v1/v1/upload?type=1"
                    list-type="picture-card"
                    :file-list="pieceForm.license ? [{url: pieceForm.license}] : []"
                    :on-success="idCardUpldate"
                    :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" :modal="false">
                  <img width="100%" :src="this.preview" alt="">
                </el-dialog>
              </el-form-item>
              <el-form-item v-if="pieceForm.incomType === 0" prop="aptitude" label="资质类型"
                            style="display: flex;align-items: center;" class="dyIn">
                <el-select  style="" clearable size="small" v-model="pieceForm.aptitude" placeholder="请选择">
                  <el-option label="个体户" :value="0"></el-option>
                  <el-option label="企业" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="pieceForm.incomType === 0" prop="registerUser" label="注册名称"
                            style="display: flex;align-items: center;" class="dyIn">
                <el-input  clearable placeholder="注册名称" size="mini" v-model="pieceForm.registerUser"></el-input>
              </el-form-item>
              <el-form-item v-if="pieceForm.incomType === 0" prop="identifier" label="社会信用代码"
                            style="display: flex;align-items: center;"
                            class="dyIn">
                <el-input  clearable style="width: 230px;" placeholder="注册等级号/统一社会信用代码" size="mini"
                          v-model="pieceForm.identifier"></el-input>
              </el-form-item>
              <el-form-item v-if="pieceForm.incomType === 0" prop="licenseStartDate" label="执照有效期"
                            style="display: flex;align-items: center;" class="dyIn">
                <el-date-picker
                    size="mini"
                    style="width: 130px"
                    v-model="pieceForm.licenseStartDate"
                    type="date"
                    placeholder="开始日期">
                </el-date-picker>
                <span>-</span>
                <el-date-picker
                    size="mini"
                    style="width: 130px"
                    :="cq"
                    v-model="pieceForm.licenseEndDate"
                    type="date"
                    placeholder="结束日期">
                </el-date-picker>
                <el-checkbox  style="margin-left: 10px;" v-model="cq">长期</el-checkbox>
              </el-form-item>
              <el-form-item v-if="pieceForm.incomType == 0" prop="registerAddress" label="注册地址"
                            style="display: flex;align-items: center;"
                            class="dyIn">
                <el-input  clearable type="textarea" :rows="2" style="width: 350px;" placeholder="注册地址" size="mini"
                          v-model="pieceForm.registerAddress"></el-input>
              </el-form-item>
              <el-form-item prop="idCardStart" label="身份证人像面" style="display: flex;align-items: center;"
                            class="dyIn">
                <el-upload
                    :limit="1"
                    :headers="{'Authorization': this.$store.getters.token}"
                    action="/v1/v1/upload?type=2"
                    list-type="picture-card"
                    :show-file-list="true"
                    :file-list="pieceForm.idCardStart ? [{url: pieceForm.idCardStart}] : undefined"
                    :on-success="idCardUpldate2"
                    :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" :modal="false">
                  <img width="100%" :src="this.preview" alt="">
                </el-dialog>
              </el-form-item>
              <el-form-item prop="idCardEnd" label="身份证国徽面" style="display: flex;align-items: center;"
                            class="dyIn">
                <el-upload
                    :limit="1"
                    :headers="{'Authorization': this.$store.getters.token}"
                    action="/v1/v1/upload?type=3"
                    list-type="picture-card"
                    :file-list="pieceForm.idCardEnd ? [{url: pieceForm.idCardEnd}] : []"
                    :on-success="idCardUpldate3"
                    :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" :modal="false">
                  <img width="100%" :src="this.preview" alt="">
                </el-dialog>
              </el-form-item>
              <el-form-item prop="legalPerson" label="法人姓名" style="display: flex;align-items: center;"
                            class="dyIn">
                <el-input  clearable placeholder="法人姓名" size="mini" v-model="pieceForm.legalPerson"></el-input>
              </el-form-item>
              <el-form-item prop="idCard" label="法人证件号" style="display: flex;align-items: center;"
                            class="dyIn">
                <el-input  clearable placeholder="法人证件号" size="mini" v-model="pieceForm.idCard"></el-input>
              </el-form-item>
              <el-form-item prop="idCardDateStart" label="证件有效期" style="display: flex;align-items: center;"
                            class="dyIn">
                <el-date-picker

                    size="mini"
                    style="width: 130px"
                    v-model="pieceForm.idCardDateStart"
                    type="date"
                    placeholder="开始日期">
                </el-date-picker>
                <span>-</span>
                <el-date-picker

                    size="mini"
                    style="width: 130px"
                    v-model="pieceForm.idCardDateEnd"
                    type="date"
                    placeholder="结束日期">
                </el-date-picker>
                <el-checkbox  style="margin-left: 10px;" v-model="cq2">长期</el-checkbox>
              </el-form-item>
            </div>
            <div style="margin-top: 40px;">
              <span style="font-size: 12px;font-weight: 600">基础信息</span>
              <el-divider/>
              <div>
                <el-form-item prop="mchFor" label="商户简称" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-input  clearable placeholder="商户简称" size="mini" v-model="pieceForm.mchFor"></el-input>
                </el-form-item>
                <el-form-item prop="userName" label="联系人姓名" style="display: flex;align-items: center;"
                              class="dyIn">
                 <div style="display:flex;">
                   <el-input  clearable placeholder="联系人姓名" size="mini"
                             v-model="pieceForm.userName"></el-input>
                   <el-checkbox  style="margin-left: 15px;" size="mini" v-model="checked">(自动补全)</el-checkbox>
                 </div>
                </el-form-item>
                <el-form-item prop="phone" label="联系人手机号" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-input  clearable :rows="2" placeholder="联系人手机号" size="mini"
                            v-model="pieceForm.phone"></el-input>
                </el-form-item>
                <el-form-item prop="mccId" label="商户类别" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-cascader
                      v-model=value
                      size="mini"
                      :options="options"
                      placeholder="商户类别"
                      style="width: 300px;"
                      :props="{ expandTrigger: 'hover' }"
                      @change="handleChange"></el-cascader>
                </el-form-item>
                <el-form-item prop="manageCity" label="经营区域" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-cascader
                      v-model="value3"
                      size="mini"
                      :options="options3"
                      placeholder="经营区域"
                      style="width: 300px;"
                      :props="{ expandTrigger: 'hover' }"
                      @change="handleChange2"></el-cascader>
                </el-form-item>
                <el-form-item prop="manageCityAddress" label="经营地址" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-input  clearable type="textarea" :rows="2" style="width: 350px;" placeholder="经营地址" size="mini"
                            v-model="pieceForm.manageCityAddress"></el-input>
                </el-form-item>
                <el-form-item prop="doorPhoto" label="门头照" style="display: flex;align-items: center;" class="dyIn">
                  <el-upload
                      :limit="1"
                      :headers="{'Authorization': this.$store.getters.token}"
                      action="/v1/v1/upload?type=4"
                      list-type="picture-card"
                      :file-list="pieceForm.doorPhoto ? [{url: pieceForm.doorPhoto}] : []"
                      :on-success="idCardUpldate4"
                      :on-preview="handlePictureCardPreview">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" :modal="false">
                    <img width="100%" :src="this.preview" alt="">
                  </el-dialog>
                </el-form-item>
                <el-form-item prop="interiorPhoto" label="内景照" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-upload
                      :limit="1"
                      :headers="{'Authorization': this.$store.getters.token}"
                      action="/v1/v1/upload?type=5"
                      list-type="picture-card"
                      :file-list="pieceForm.interiorPhoto ? [{url: pieceForm.interiorPhoto}] : []"
                      :on-success="idCardUpldate4"
                      :on-preview="handlePictureCardPreview">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" :modal="false">
                    <img width="100%" :src="this.preview" alt="">
                  </el-dialog>
                </el-form-item>
                <el-form-item prop="checkstandPhoto" label="收银台照" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-upload
                      :limit="1"
                      :headers="{'Authorization': this.$store.getters.token}"
                      action="/v1/v1/upload?type=6"
                      list-type="picture-card"
                      :file-list="pieceForm.checkstandPhoto ? [{url: pieceForm.checkstandPhoto}] : []"
                      :on-success="idCardUpldate4"
                      :on-preview="handlePictureCardPreview">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" :modal="false">
                    <img width="100%" :src="this.preview" alt="">
                  </el-dialog>
                </el-form-item>
              </div>
            </div>
            <div style="margin-top: 40px;">
              <span style="font-size: 12px;font-weight: 600">结算信息</span>
              <el-divider/>
              <div>
                <el-form-item prop="isUncrpSett" label="结算类型" class="dyIn">
                  <el-radio-group v-model="pieceForm.isUncrpSett" size="mini">
                    <el-radio :label="0">对私(默认)</el-radio>
                    <el-radio :label="1">对公</el-radio>
                    <el-radio :label="2">非法人</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item  v-if="pieceForm.isUncrpSett !== 1" prop="bankCardPhoto" label="银行卡卡号面" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-upload
                      :limit="1"
                      :headers="{'Authorization': this.$store.getters.token}"
                      action="/v1/v1/upload?type=7"
                      list-type="picture-card"
                      :file-list="pieceForm.bankCardPhoto ? [{url: pieceForm.bankCardPhoto}] : []"
                      :on-success="idCardUpldate5"
                      :on-preview="handlePictureCardPreview">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" :modal="false">
                    <img width="100%" :src="this.preview" alt="">
                  </el-dialog>
                </el-form-item>
                <el-form-item prop="settlementName" label="结算人姓名" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-input  clearable placeholder="结算人姓名" size="mini"
                            v-model="pieceForm.settlementName"></el-input>
                </el-form-item>
                <el-form-item prop="settlementIdCard" label="结算人身份证号" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-input clearable placeholder="结算人身份证号" size="mini"
                            v-model="pieceForm.settlementIdCard"></el-input>
                </el-form-item>
                <el-form-item prop="bankCard" label="结算银行卡卡号" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-input  clearable placeholder="结算银行卡卡号" size="mini"
                            v-model="pieceForm.bankCard"></el-input>
                </el-form-item>
                <el-form-item prop="settlementPhone" label="银行预留手机号" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-input  clearable :rows="2" placeholder="银行预留手机号" size="mini"
                            v-model="pieceForm.settlementPhone"></el-input>
                </el-form-item>
                <el-form-item prop="brank" label="开户行" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-select  v-model="pieceForm.brank" placeholder="开户行" size="mini">
                    <el-option
                        filterable
                        v-for="item in options4"
                        :key="item.bankName"
                        :label="item.bankName"
                        :value="item.bankName">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="openingCity" label="开户城市" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-cascader
                    v-model="value2"
                    size="mini"
                    :options="options3"
                    placeholder="开户城市"
                    style="width: 300px;"
                    :props="{ expandTrigger: 'hover' }"
                    @change="handleChange4"></el-cascader>
                </el-form-item>
                <el-form-item prop="subBranchCode" label="开户支行" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-input  clearable type="textarea" :rows="2" style="width: 350px;" placeholder="开户支行" size="mini"
                             v-model="pieceForm.subBranchCode"></el-input>
                </el-form-item>

                <el-form-item label="开户许可证照" style="display: flex;align-items: center;"
                              class="dyIn" v-if="pieceForm.isUncrpSett === 1">
                  <el-upload
                      :limit="1"
                      action=""
                      list-type="picture-card"
                      :class="hideUpload12 ? 'hide' : ''"
                      :file-list="pieceForm.accountOpeningPermit ? [{url: pieceForm.accountOpeningPermit}]  : undefined"
                      :http-request="handleUpload"
                      :on-success="idCardUpldate12"
                      :on-change="changeImage12"
                      :before-upload="beforeAvatarUpload"
                      :on-remove="removeImage12"
                      :on-preview="handlePictureCardPreview">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" :modal="false">
                    <img width="100%" :src="this.preview" alt="">
                  </el-dialog>
                </el-form-item>
                <div v-if="pieceForm.isUncrpSett === 2 || pieceForm.isUncrpSett === 0">
                  <el-form-item label="非法人身份证正面照" style="display: flex;align-items: center;" v-if="pieceForm.tenantId !== 4"
                                class="dyIn">
                    <el-upload
                        :limit="1"
                        action=""
                        list-type="picture-card"
                        :class="hideUpload8 ? 'hide' : ''"
                        :file-list="pieceForm.uncrpSettIdCardStart ? [{url: pieceForm.uncrpSettIdCardStart}]  : undefined"
                        :http-request="handleUpload"
                        :on-success="idCardUpldate8"
                        :on-change="changeImage8"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="removeImage8"
                        :on-preview="handlePictureCardPreview">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible" :modal="false">
                      <img width="100%" :src="this.preview" alt="">
                    </el-dialog>
                  </el-form-item>
                  <el-form-item label="非法人身份证反面照" prop="uncrpSettIdcardEnd" class="dyIn"  style="display: flex;align-items: center;" v-if="pieceForm.tenantId !== 4">
                    <el-upload
                        :limit="1"
                        action=""
                        list-type="picture-card"
                        :class="hideUpload9 ? 'hide' : ''"
                        :file-list="pieceForm.uncrpSettIdCardEnd ? [{url: pieceForm.uncrpSettIdCardEnd}]  : undefined"
                        :http-request="handleUpload"
                        :on-success="idCardUpldate9"
                        :on-change="changeImage9"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="removeImage9"
                        :on-preview="handlePictureCardPreview">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible" :modal="false">
                      <img width="100%" :src="this.preview" alt="">
                    </el-dialog>
                  </el-form-item>
                  <el-form-item label="非法人结算授权书照" style="display: flex;align-items: center;"
                                class="dyIn">
                    <el-upload
                        :limit="1"
                        action=""
                        list-type="picture-card"
                        :class="hideUpload10 ? 'hide' : ''"
                        :file-list="pieceForm.uncrpAuthPhoto ? [{url: pieceForm.uncrpAuthPhoto}]  : undefined"
                        :http-request="handleUpload"
                        :on-success="idCardUpldate10"
                        :on-change="changeImage10"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="removeImage10"
                        :on-preview="handlePictureCardPreview">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible" :modal="false">
                      <img width="100%" :src="this.preview" alt="">
                    </el-dialog>
                  </el-form-item>
                  <el-form-item label="非法人手持结算授权书照" style="display: flex;align-items: center;"
                                class="dyIn">
                    <el-upload
                        :limit="1"
                        action=""
                        list-type="picture-card"
                        :class="hideUpload11 ? 'hide' : ''"
                        :file-list="pieceForm.uncrpAuthHandPhoto ? [{url: pieceForm.uncrpAuthHandPhoto}]  : undefined"
                        :http-request="handleUpload"
                        :on-success="idCardUpldate11"
                        :on-change="changeImage11"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="removeImage11"
                        :on-preview="handlePictureCardPreview">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible" :modal="false">
                      <img width="100%" :src="this.preview" alt="">
                    </el-dialog>
                  </el-form-item>

                  <!--银盛备用结算对公账户-->
                  <el-divider content-position="left"><p class="fontStyle">备用结算对公账户</p></el-divider>
                  <el-form-item label="备用结算账户图片" style="display: flex;align-items: center;" v-if="pieceForm.tenantId !== 4"
                                class="dyIn">
                    <el-upload
                      :limit="1"
                      action=""
                      list-type="picture-card"
                      :class="hideUpload13 ? 'hide' : ''"
                      :file-list="pieceForm.standByStlAccPhoto ? [{url: pieceForm.standByStlAccPhoto}]  : undefined"
                      :http-request="handleUpload"
                      :on-success="idCardUpldate13"
                      :on-change="changeImage13"
                      :before-upload="beforeAvatarUpload"
                      :on-remove="removeImage13"
                      :on-preview="handlePictureCardPreview">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible" :modal="false">
                      <img width="100%" :src="this.preview" alt="">
                    </el-dialog>
                  </el-form-item>
                  <el-form-item prop="settlementPhone" label="备用结算账号" style="display: flex;align-items: center;"
                                class="dyIn">
                    <el-input clearable :rows="2" placeholder="备用结算账号" size="mini"
                              v-model="pieceForm.standByStlAccNo"></el-input>
                  </el-form-item>
                  <el-form-item prop="settlementPhone" label="备用结算户名" style="display: flex;align-items: center;"
                                class="dyIn">
                    <el-input clearable :rows="2" placeholder="备用结算户名" size="mini"
                              v-model="pieceForm.standByStlAccNm"></el-input>
                  </el-form-item>
                  <el-form-item prop="openingCity" label="备用结算账户开户城市" style="display: flex;align-items: center;"
                                class="dyIn">
                    <el-cascader
                      v-model="standByStlValue2"
                      size="mini"
                      :options="options3"
                      placeholder="备用结算账户开户城市"
                      style="width: 300px;"
                      :props="{ expandTrigger: 'hover' }"
                      @change="standByStlHandleChange4"></el-cascader>
                  </el-form-item>
                  <el-form-item prop="brank" label="备用结算账户开户行" style="display: flex;align-items: center;"
                                class="dyIn">
                    <el-select v-model="pieceForm.standByBank" placeholder="备用结算账户开户行" size="mini"
                               @change="standByStlBrankChange">
                      <el-option
                        filterable
                        v-for="item in standByStlOptions4"
                        :key="item.bankName"
                        :label="item.bankName"
                        :value="item.bankCode">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item prop="standByBankSubCode" label="备用结算账户开户支行" style="display: flex;align-items: center;"
                                class="dyIn">
                    <el-select v-model="pieceForm.standByBankSubCode" filterable placeholder="备用结算账户开户支行" size="mini"
                               style="width: 300px">
                      <el-option
                        filterable
                        v-for="item in standByStlOptions5"
                        :key="item.openBankName"
                        :label="item.openBankName"
                        :value="item.openBankName">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  
                </div>
                
                <el-form-item prop="mchId" label="选择进件商户" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-autocomplete
                      popper-class="my-autocomplete"
                      v-model="pieceForm.mchId"
                      style="width: 280px"
                      size="mini"
                      :fetch-suggestions="querySearch"
                      placeholder="请选择要进件的商户"
                      @select="handleSelect">
                    <template slot-scope="{ item }" style="line-height: 0px;">
                      <div style="display: flex;flex-direction: column">
                        <span class="name">商户名称：{{ item.mname }}</span>
                        <span class="addr" style="margin-top: -10px;">商户号：{{ item.mid }}</span>
                      </div>
                    </template>
                    >
                  </el-autocomplete>
                </el-form-item>
                <el-form-item prop="tempId" label="费率模板选择" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-autocomplete
                      popper-class="my-autocomplete"
                      v-model="pieceForm.tempId"
                      style="width: 280px"
                      size="small"
                      @select="rateTemplateChange"
                      :fetch-suggestions="autoCompleteRateTemp"
                      placeholder="请选择费率模板">
                    <template slot-scope="{ item }" style="line-height: 0px;">
                      <div style="display: flex;flex-direction: column;font-size: 10px">
                        <span class="name">模板名称：{{ item.tempName }}</span>
                        <span class="addr" style="margin-top: -10px;font-size: 10px">微信费率：{{ item.wxRate }}%</span>
                        <span class="addr" style="margin-top: -10px;font-size: 10px">支付宝费率：{{
                            item.aliRate
                          }}%</span>
                      </div>
                    </template>
                    >
                  </el-autocomplete>
                </el-form-item>
                <el-form-item prop="tenantId" label="进件平台" style="display: flex;align-items: center;"
                              class="dyIn">
                  <el-select v-model="pieceForm.tenantId" filterable placeholder="请进件平台" size="mini"
                             style="width: 300px">
                    <el-option
                        filterable
                        v-for="item in agentTenantList"
                        :key="item.id"
                        :label="item.tenantName"
                        :value="item.tenantId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <div style="text-align: center;">
            <el-button type="primary" size="small" @click="okjj(1,'')">通过</el-button>
            <el-button size="small" style="margin-left: 20px;" @click="bh()">驳回</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {rateTempList,getAgentTenantList} from "@/api/agent/agent";
import {list} from "@/api/mch/mch";
import {addPiece} from "@/api/piece/piece";

export default {
  name: "ViewPiece",
  props:{
    formValue: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      rateTempList: [],
      agentTenantList: [],
      restaurants: [],
      cq: false,
      updateUrl: "http://hss.fwypay.com/v1/upload?type=",
      cq2: false,
      dialogVisible: false,
      preview: '',
      value: [],
      value2: [],
      checked: false,
      value3: [],
      value4: [],
      options: JSON.parse(localStorage.getItem("initData")).mcc,
      options2: JSON.parse(localStorage.getItem("initData")).area.city,
      options3: JSON.parse(localStorage.getItem("initData")).area.area,
      options4: JSON.parse(localStorage.getItem("initData")).bank,
      incomType: 0,
      pieceForm: {
        license: '',
        incomType: 0,
        aptitude: '',
        identifier: '',
        registerAddress: '',
        legalPerson: '',
        registerUser: '',
        idCardStart: '',
        idCardEnd: '',
        licenseStartDate: '',
        licenseEndDate: '',
        idCardDateStart: '',
        idCardDateEnd: '',
        idCard: '',
        mchFor: '',
        userName: '',
        phone: '',
        mccId: '',
        manageCity: '',
        manageCityAddress: '',
        doorPhoto: '',
        interiorPhoto: '',
        checkstandPhoto: '',
        bankCardPhoto: '',
        settlementName: '',
        bankCard: '',
        settlementPhone: '',
        incomStatus: '',
        brank: '',
        openingCity: '',
        subBranch: '',
        remark: '',
        mchId: '',
        tenantId: 1,
        isUncrpSett: 0,
        uncrpSettIdCardStart: '',
        uncrpSettIdCardEnd: '',
        uncrpAuthPhoto: '',
        uncrpAuthHandPhoto: '',
        accountOpeningPermit: '',
        standByStlAccPhoto: '',
        standByStlAccNo: '',
        standByStlAccNm: '',
        standByBankSubCode: '',
        standByBankProvince: '',
        standByBankCity: '',
        standByBank: '',
        // 结算人身份证号
        settlementIdCard: '',
        agentTenantId: '',
        // 开户支行代码
        subBranchCode: '',
        agentId: this.$store.getters.userInfo.id
      },
      pieceFormRules: {
        license: [
          {validator: this.licenseValid, trigger: 'blur'}
        ],
        registerUser: [
          {validator: this.registerUserValid, trigger: 'blur'}
        ],
        identifier: [
          {validator: this.identifierValid, trigger: 'blur'}
        ],
        licenseStartDate: [
          {validator: this.licenseStartDateValid, trigger: 'blur'}
        ],
        idCardStart: [
          {required: true, message: '身份证正面照不能为空', trigger: 'blur'}
        ],
        idCardEnd: [
          {required: true, message: '身份证反面照不能为空', trigger: 'blur'}
        ],
        legalPerson: [
          {required: true, message: '法人姓名不能为空', trigger: 'blur'}
        ],
        idCard: [
          {required: true, message: '法人证件号不能为空', trigger: 'blur'}
        ],
        idCardDateStart: [
          {required: true, message: '身份证期限不能为空', trigger: 'blur'}
        ],
        mchFor: [
          {required: true, message: '商户简称不能为空', trigger: 'blur'}
        ],
        userName: [
          {required: true, message: '联系人姓名不能为空', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '联系人手机号不能为空', trigger: 'blur'}
        ],
        mccId: [
          {required: true, message: '商户类别不能为空', trigger: 'blur'}
        ],
        manageCity: [
          {required: true, message: '经营区域不能为空', trigger: 'blur'}
        ],
        manageCityAddress: [
          {required: true, message: '经营地址不能为空', trigger: 'blur'}
        ],
        doorPhoto: [
          {required: true, message: '门头照不能为空', trigger: 'blur'}
        ],
        interiorPhoto: [
          {required: true, message: '内景照不能为空', trigger: 'blur'}
        ],
        checkstandPhoto: [
          {required: true, message: '收银台照不能为空', trigger: 'blur'}
        ],
        // bankCardPhoto: [
        //   {required: true, message: '银行卡卡号面不能为空', trigger: 'blur'}
        // ],
        settlementName: [
          {required: true, message: '结算人姓名不能为空', trigger: 'blur'}
        ],
        settlementPhone: [
          {required: true, message: '银行预留手机号不能为空', trigger: 'blur'}
        ],
        // bankCard: [
        //   {required: true, message: '银行卡卡号不能为空', trigger: 'blur'}
        // ],
        brank: [
          {required: true, message: '开户行不能为空', trigger: 'blur'}
        ],
        openingCity: [
          {required: true, message: '开户城市不能为空', trigger: 'blur'}
        ],
        subBranch: [
          {required: true, message: '开户支行不能为空', trigger: 'blur'}
        ],
        tenantId: [
          {required: true, message: '请选择要进件的渠道', trigger: 'blur'}
        ],
        tempId: [
          {required: true, message: '请选择费率模板', trigger: 'blur'}
        ],
        mchId: [
          {required: true, message: '请选择要进件的商户', trigger: 'blur'}
        ]
      }
    }
  },
  mounted(){
    if (JSON.stringify(this.formValue) !== "{}"){
      this.pieceForm = this.formValue
      let city = this.options3.filter(x => x.label === this.pieceForm.manageCity.split("/")[0])[0];
      let city2 = city.children.filter(x => x.label === this.pieceForm.manageCity.split("/")[1])[0];
      let city3 = city2.children.filter(x => x.label === this.pieceForm.manageCity.split("/")[2])[0];
      // 经营区域
      this.value3 = [city.value,city2.value,city3.value]
      this.initAgentTenantList()
      this.initMchList()
      this.rateTemplateList()

      let city4 = this.options3.filter(x => x.label === this.pieceForm.openingCity.split("/")[0])[0];
      let city5 = city.children.filter(x => x.label === this.pieceForm.openingCity.split("/")[1])[0];
      // 开户城市
      this.value2 = [city4.value,city5.value]
    //   商户类别
      debugger;
      if (this.pieceForm.mccId){
        this.options.forEach(x =>{
          x.children.forEach(y =>{
            if (y.value == this.pieceForm.mccId){
              this.value = [x.value,y.value]
            }
          })
        })
      }
    }
  },
  watch: {
    checked(val){
      if (val){
        this.pieceForm.userName = this.pieceForm.legalPerson;
      }else{
        this.pieceForm.userName = ''
      }
    },
    cq(val) {
      if (this.cq) {
        this.pieceForm.licenseEndDate = '2999-12-31'
      } else {
        this.pieceForm.licenseEndDate = ''
      }
    },
    cq2(val) {
      if (this.cq2) {
        this.pieceForm.idCardDateEnd = '2999-12-31'
      } else {
        this.pieceForm.idCardDateEnd = ''
      }
    }
  },
  methods: {
    rateTemplateList() {
      let param = {
        pageNum: 1,
        pageSize: 100,
        agentId: this.$store.getters.userInfo.id
      }
      rateTempList(param).then(res => {
        if (res.success) {
          this.rateTempList = res.data.list;
        }
      })
    },
    rateTemplateChange(item) {
      this.pieceForm.tempId = item.id
    },
    autoCompleteRateTemp(queryString, cb) {
      let restaurants = this.rateTempList;
      let results = queryString ? restaurants.filter(x => x.id === queryString) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    initMchList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
        aId: this.$store.getters.userInfo.id
      }
      list(data).then(res => {
        this.restaurants = res.data.list
      })
    },
    initAgentTenantList() {
      getAgentTenantList(this.$store.getters.userInfo.id).then(res => {
        if (res.success) {
          this.agentTenantList = res.data
        }
      })
    },
    bh(){
      this.$prompt('请输入驳回理由', '信息', {
        confirmButtonText: '驳回',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.okjj(3,value)
      });
    },
    removeEmptyValues(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
          delete obj[key];
        } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
      return obj;
    },
    licenseValid(rule, value, callback) {
      if (this.pieceForm.incomType === 0) {
        if (value) {
          callback()
        } else {
          callback(new Error('营业执照不能为空'))
        }
      } else {
        callback()
      }
    },
    aptitudeValid(rule, value, callback) {
      if (this.pieceForm.incomType === 0) {
        if (value) {
          callback()
        } else {
          callback(new Error('资质类型不能为空'))
        }
      } else {
        callback()
      }
    },
    registerUserValid(rule, value, callback) {
      if (this.pieceForm.incomType === 0) {
        if (value) {
          callback()
        } else {
          callback(new Error('注册名称不能为空'))
        }
      } else {
        callback()
      }
    },
    identifierValid(rule, value, callback) {
      if (this.pieceForm.incomType === 0) {
        if (value) {
          callback()
        } else {
          callback(new Error('注册登记号不能为空'))
        }
      } else {
        callback()
      }
    },
    licenseStartDateValid(rule, value, callback) {
      if (this.pieceForm.incomType === 0) {
        if (value) {
          callback()
        } else {
          callback(new Error('营业执照期限不能为空'))
        }
      } else {
        callback()
      }
    },
    okjj(status,remark) {
      console.log(JSON.stringify(this.pieceForm));
      if(status == 3){
        this.pieceForm.remark = remark;
      }
      this.pieceForm.incomStatus = status;
      this.$refs['pieceForm'].validate((valid) => {
        if (valid) {
          addPiece(this.removeEmptyValues(JSON.parse(JSON.stringify(this.pieceForm)))).then(res => {
              if (res.success){
                this.$message.success("操作成功！")
                this.$parent.changeType();
              }
          })
        } else {
          return false;
        }
      });
    },
    handleChange(val) {
      this.pieceForm.mccId = parseInt(val[1])
    },
    handleChange2(val) {
      let city = this.options3.filter(x => x.value === val[0])[0];
      let city2 = city.children.filter(x => x.value === val[1])[0];
      let city3 = city2.children.filter(x => x.value === val[2])[0];
      this.pieceForm.manageCity = city.label + "/" + city2.label + "/" + city3.label;
    },
    handleChange3(val) {

    },
    handleChange4(val) {
      let city = this.options3.filter(x => x.value === val[0])[0];
      let city2 = city.children.filter(x => x.value === val[1])[0];
      this.pieceForm.openingCity = city.label + "/" + city2.label;
    },
    transDate(date) {
      let a = '';
      a = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
      return a;
    },
    idCardUpldate(response, file, fileList) {
      this.pieceForm.license = response.data.img;
      this.pieceForm.registerUser = response.data.name
      this.pieceForm.identifier = response.data.reg_num
      this.pieceForm.licenseStartDate = this.transDate(response.data.establish_date)
      this.pieceForm.licenseEndDate = this.transDate(response.data.valid_period)
      this.pieceForm.registerAddress = response.data.address
      this.pieceForm.aptitude = response.data.type == '个体工商户' ? 0 : 1
      if (response.data.valid_period == "29991231") {
        this.cq = true;
      }
    },
    idCardUpldate2(response, file, fileList) {
      this.pieceForm.idCardStart = response.data.img;
      this.pieceForm.legalPerson = response.data.name;
      this.pieceForm.idCard = response.data.num;
    },
    idCardUpldate3(response, file, fileList) {
      this.pieceForm.idCardEnd = response.data.img;
      this.pieceForm.idCardDateStart = this.transDate(response.data.start_date)
      this.pieceForm.idCardDateEnd = this.transDate(response.data.end_date)
      if (response.data.end_date == "29991231") {
        this.cq2 = true;
      }
    },
    idCardUpldate4(response, file, fileList) {
      if (response.data.upload_type == 4) {
        this.pieceForm.doorPhoto = response.data.img;
      } else if (response.data.upload_type == 5) {
        this.pieceForm.interiorPhoto = response.data.img;
      } else {
        this.pieceForm.checkstandPhoto = response.data.img;
      }
    },
    idCardUpldate5(response, file, fileList) {
      this.pieceForm.bankCardPhoto = response.data.img;
      this.pieceForm.bankCard = response.data.card_num;
    },
    handlePictureCardPreview(file) {
      this.preview = file.url;
      this.dialogVisible = true;
    },
    backList() {
      this.$parent.changeType()
    },
    handleSelect(item) {
      this.pieceForm.mchId = item.mid
    },
    querySearch(queryString, cb) {
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
  }
}
</script>

<style lang="scss" scoped>
.my-autocomplete {

  li {
    line-height: normal;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}

/deep/ .dyIn .el-form-item__content {
  margin-left: 0px !important;
}

/deep/ .dyIn .el-form-item__label {
  font-size: 12px;
  color: #000000;
}

/deep/ .dyIn .el-form-item__error {
  width: 150px;
}
.diycard{
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
}
</style>

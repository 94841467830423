import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "../components/Layout.vue";
import Home from "../views/home/Home.vue";
import Index from "../views/mch/Index.vue";
import InputPieceSh from "@/views/mch/InputPieceSh.vue";
import Login from "@/views/login/login.vue";

Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  { path: '/', name: 'index', component: Layout, redirect: '/welcome',
    meta: { title: '工作台', hidden: true, raking: 1, auth: ['A','M'], icon: 'iconfont el-icon-luzhang' },
    children: [ { path: 'home', name: 'home', 
      meta: { title: '工作台', },
        component: () => import('@/views/home/Home.vue') },
      { path: 'pieceManager', name: 'pieceManager', 
        meta: { title: '审核管理', },
        component: () => import('@/views/mch/InputPieceSh.vue') },
      { path: 'channelManager', name: 'channelManager', 
        meta: { title: '通道配置', },
        component: () => import('@/views/agent/TenantSettings.vue') },
      { path: 'driverManager', name: 'driverManager', 
        meta: { title: '终端配置',  },
        component: () => import('@/views/agent/TenantSettings.vue') } ]  },
  { path: '/welcome', name: 'welcome', 
    meta: { title: '工作台', hidden: true },
    component: () => import('@/views/home/Welcome.vue') },
  { path: '/home', name: 'home', raking: 1, auth: ['A','M'], 
    meta: { title: '工作台', icon: 'iconfont el-icon-moxing' } },
  { path: '/login', name: 'login',
    meta: { title: '登录', hidden: true },
    component: () => import('@/views/login/login.vue') },
  { path: '/pay', name: 'pay', 
    meta: { title: '登录', hidden: true },
    component: () => import('@/views/pay/pay.vue') },
  { path: '/wxJump', name: 'wxJump',
    meta: { title: '', hidden: true },
    component: () => import('@/views/pay/wxJump.vue') },
  { path: '/h5pay', name: 'pay', 
    meta: { title: '登录', hidden: true },
    component: () => import('@/views/pay/h5Pay.vue') },
  { path: '/paySuccess', name: 'pay', 
    meta: { title: '登录', hidden: true },
    component: () => import('@/views/pay/paySuccess.vue') },
  { path: '/alipay', name: 'alipay', 
    meta: { title: '登录', hidden: true },
    component: () => import('@/views/pay/alipay.vue') },
  { path: '/wxPay', name: 'wxPay', 
    meta: { title: '登录', hidden: true },
    component: () => import('@/views/pay/wxPay.vue') },
  { path: '/rwmPay', name: 'rwmPay', 
    meta: { title: '登录', hidden: true },
    component: () => import('@/views/pay/rwmPay.vue') },
  { path: '/', name: 'welcome', 
    meta: { title: '工作台', hidden: true },
    component: () => import('@/views/home/Welcome.vue') },
  { path: '/alipaysuccess', name: 'paysuccess', 
    meta: { title: '工作台', hidden: true },
    component: () => import('@/views/pay/alipaySuccess.vue') },
  { path: '/test', name: 'test', 
    meta: { title: '工作台', hidden: true },
    component: () => import('@/views/test.vue') },
  { path: '/test2', name: 'test2', 
    meta: { title: '工作台', hidden: true },
    component: () => import('@/views/test2.vue') },
  { path: '/agent', name: 'agent', 
    meta: { title: '代理商管理', raking: 2, auth: ['A'], icon: 'iconfont el-icon-tongxun' },
    component: Layout,
    children: [
      { path: 'list', name: 'agentList', 
        meta: { auth: ['A'], upTitle: '代理商管理', title: '代理商列表' },
        component: () => import('@/views/agent/Index.vue') },
      { path: 'rateTemplate', name: 'rateTemplate', 
        meta: { auth: ['test'], isNew: true, upTitle: '代理商管理', title: '费率配置' },
        component: () => import('@/views/agent/RateTemplate.vue') }
    ]
  },
  { path: '/mch', name: 'mch', component: Layout, 
    meta: { raking: 3, auth: ['A','M'], title: '商户管理', icon: 'iconfont el-icon-luzhang' },
    children: [
      { path: 'mchList', name: 'mchList', 
        meta: { upTitle: '商户管理', title: '商户列表', },
        component: () => import('@/views/mch/Index.vue') },
      { path: 'mch-detail', name: 'mchDetail', 
        meta: { title: '详情页', upTitle: '商户管理', hidden: true },
        component: () => import('@/views/mch/component/MchDetail.vue')},
      { path: 'diyPiece', name: 'diyPiece', 
        meta: { title: '手动进件', upTitle: '商户管理', hidden: true },
        component: () => import('@/views/mch/component/DiyPiece.vue') },
      { path: 'shengfutongAddTenant', name: '盛付通进件', 
        meta: { title: '盛付通进件', hidden: true },
        component: () => import('@/views/sft/shengfutongAddTenant.vue') },
      { path: 'inputPiece', name: 'inputPiece', 
        meta: { auth: ['A','M'], upTitle: '商户管理', title: '进件管理' },
        component: () => import('@/views/mch/InputPiece.vue') },
      { path: 'integral', name: 'integral', 
        meta: { auth: ['A','M'], upTitle: '商户管理', title: '轮询管理' },
        component: () => import('@/views/mch/Integral.vue') }
    ]
  },
  { path: '/order', name: 'order', 
    meta: { title: '订单管理', raking: 4, auth: ['A','M'], icon: 'iconfont el-icon-zhexiantu' },
    component: Layout,
    children: [
      { path: 'orderList', name: 'orderList', 
        meta: { auth: ['A','M'], upTitle: '订单管理', title: '订单列表' },
        component: () => import('@/views/order/List.vue') },
      { path: 'separate', name: 'separate',
        meta: { auth: ['A'], upTitle: '订单管理', title: '分账管理' },
        component: () => import('@/views/order/Separate.vue') },
      { path: 'settleList', name: 'settleList', meta: { auth: ['A','M'], upTitle: '订单管理', title: '结算列表' },
        component: () => import('@/views/order/SettleList.vue') }
    ]
  },
  { path: '/pieceManager', name: 'pieceManager', 
    component: InputPieceSh, 
    meta: { raking: 5, auth: ['A'], title: '审核管理', icon: 'iconfont el-icon-yulan' }
  },
  { path: '/account', name: 'account', component: Layout,
    meta: { title: '账户管理', auth: ['A','M'], raking: 6, icon: 'iconfont el-icon-qianbao' },
    children: [
      { path: 'purse', name: 'purse', 
        meta: { auth: ['A','M'], title: '钱包', upTitle: '账户管理', },
        component: () => import('@/views/account/Purse.vue') },
      { path: 'key', name: 'key', 
        meta: { auth: ['A','M'], title: '密钥管理', upTitle: '账户管理', },
        component: () => import('@/views/account/Key.vue') } ] },
  { path: '/payTg', name: 'payTg', component: Layout,
    meta: { title: '代付管理', raking: 7, auth: ['A'], icon: 'iconfont el-icon-ziyuan-tuceng' },
    children: [
      { path: 'payForAnother', name: 'payForAnother', 
        meta: { auth: ['A'], title: '安全发', isNew: true, upTitle: '安全发', },
        component: () => import('@/views/transfer/PayForAnother.vue') },
      { path: 'eqtForAnother',  name: 'eqtForAnother',
        meta: { auth: ['A'], title: 'E企通下发', isNew: true, upTitle: 'E企通下发', },
        component: () => import('@/views/transfer/EqtForAnother.vue') },
      { path: 'sftForAnother', name: 'sftForAnother',
        meta: { auth: ['A'], title: '盛付通代付', isNew: true, upTitle: '盛付通代付', },
        component: () => import('@/views/transfer/SftForAnother.vue') },
      { path: 'hjForAnother', name: 'hjForAnother',
        meta: { auth: ['A'], title: '汇聚代付', isNew: true, upTitle: '汇聚代付', },
        component: () => import('@/views/transfer/HjForAnother.vue') },
      { path: 'newAqfAnother', name: 'newAqfAnother',
        meta: { auth: ['A'], title: '新安全发', isNew: true, upTitle: '新安全发', },
        component: () => import('@/views/transfer/NewAqfAnother.vue') },] },
  { path: '/channelManager', name: 'channelManager',
    meta: { title: '通道配置', raking: 8, auth: ['A'], isNew: true, icon: 'iconfont el-icon-tansuo2' },
    component: () => import('@/views/agent/TenantSettings.vue') },
  { path: '/driverManager', name: 'driverManager',
    meta: { title: '终端配置', raking: 9, auth: ['A','M'], icon: 'iconfont el-icon-wenjianjia' },
    component: () => import('@/views/agent/DriverManager.vue') },
  
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

import store from '@/store'
router.beforeEach((to, from, next) => {
  console.log(from,"----",to)
  if (to.path == '/test2' 
    || to.path == '/test' 
    || to.path == '/alipaysuccess' 
    || to.path == '/login' 
    || to.path == '/pay'  
    || to.path == '/welcome' 
    || to.path == '/authPay' 
    || to.path == '/h5-piece' 
    || to.path == '/wxPay' 
    || to.path == '/rwmPay' 
    || to.path == '/alipay' 
    || to.path == '/h5pay' 
    || to.path === '/paySuccess'
    || to.path === '/wxJump'
  ) {
      next()
  } else {
    if (store.getters.token) {
      // let hidden = menus.filter(x => x.index === to.path)[0].hidden.indexOf(store.getters.userInfo.id.substring(0,1)) !== -1;
      // console.log(hidden,"-=----",to.path,store.getters.userInfo.id)
      // if (store.getters.userInfo.id.substring(0,1) == "M"){
      //     next("/order/list")
      // }else{
      next()
      // }
      // if (hidden) {
      //     next()
      // } else {
      //     next("/order/list")
      // }
    } else {
        next("/")
    }
  }
})
export default router;

import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API

/**
 * 查询商户列表
 * @param data
 * @returns {*}
 */
export function list(data){
    return http({
        url: `${pro}/v1/mch/list`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 新增商户
 * @param data
 * @returns {*}
 */
export function addMch(data){
    return http({
        url: `${pro}/v1/mch/addMch`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 修改轮询
 * @param query
 * @returns {*}
 */
export function updateLx(query){
    return http({
        url: `${pro}/v1/piece/updateLx`,
        method: 'GET',
        params: query
    })
}

/**
 * 获取商户名称
 * @param userCode
 * @param channel
 * @returns {*}
 */
export function getMchName(userCode,channel){
    return http({
        url: `${pro}/v1/public/getMch/${userCode}?channel=${channel}`,
        method: 'get'
    })
}

export function separateQuery(query){
  return http({
    url: `${pro}/v1/separate/query`,
    method: 'get',
    params: query
  })
}

export function separateAdd(query){
  return http({
    url: `${pro}/v1/separate/add`,
    method: 'get',
    params: query
  })
}

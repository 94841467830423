<template>
  <div >
    <el-button size="small" type="primary" @click="backList">返回列表页</el-button>
    <div style="margin-top:1cm; margin-bottom:1cm;">
      <el-tag type="danger" size="medium"><p style="font-size: 250%;">当前进件商户号：{{ this.ruleForm.mchId }}，
        支付接口名称：{{this.ruleForm.channelName}}</p></el-tag>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="350px" class="demo-ruleForm">
      <!--------------------------------------选择所属行业和基础信息开始-------------------------------------->
      <el-divider content-position="left"><p class="fontStyle">选择所属行业和基础信息</p></el-divider>
      <el-form-item label="商户类型" prop="resource">
        <el-radio-group v-model="ruleForm.incomType" @change="incomTypeSwitch" v-removeAriaHidden>
          <el-radio :label="1" label="1" >个人</el-radio>
          <el-radio :label="0" label="0" >个体工商户</el-radio>
          <el-radio :label="2" label="2" >企业</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选择行业MCC" prop="region">
        <el-cascader :options="MCCoptions" v-model="modelMccId" clearable
                        filterable @change="hangyeChange"></el-cascader>
      </el-form-item>

      <el-form-item label="商户联系人姓名" prop="name" >
        <el-input v-model="ruleForm.userName" class="reInput"></el-input>
      </el-form-item>
      <el-form-item label="商户联系人电话" prop="name">
        <el-input v-model="ruleForm.phone" class="reInput"></el-input>
      </el-form-item>
      <el-form-item label="商户联系人邮箱" prop="name">
        <el-input v-model="ruleForm.contactEmail" class="reInput"></el-input>
      </el-form-item>

      <!--------------------------------------选择所属行业和基础信息结束-------------------------------------->
      <!--------------------------------------录入经营信息开始-------------------------------------->
      <el-divider content-position="left"><p class="fontStyle">录入经营信息</p></el-divider>

      <el-form-item label="商户简称" prop="name">
        <el-input v-model="ruleForm.mchFor" class="reInput"></el-input>
      </el-form-item>
      <el-form-item label="商户名称全称" prop="name">
        <el-input v-model="ruleForm.registerUser" class="reInput"></el-input>
      </el-form-item>
      <div v-if="this.businessLicense">
        <el-form-item label="营业执照照片" prop="region">
          <el-upload
            :limit="1"
            action=""
            list-type="picture-card"
            :http-request="handleUpload"
            :file-list="licenseTiy"
            :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'businessLicense')})"
            :before-upload="beforeAvatarUpload"
            :on-remove="((file, fileList)=>{removeImg(file, fileList, 'businessLicense')})"
            :on-preview="showImg"
            :show-file-list="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="营业执照编号" prop="name">
          <el-input v-model="ruleForm.identifier" class="reInput"></el-input>
        </el-form-item>
        <el-form-item label="营业执照起始有效期" prop="name">
          <el-date-picker v-model="ruleForm.licenseStartDate" value-format="yyyy-MM-dd"
            type="date"  placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="营业执照截至有效期" prop="name">
          <el-date-picker v-model="ruleForm.licenseEndDate" value-format="yyyy-MM-dd"
                          type="date"  placeholder="选择日期"  :disabled="licenseIsDisabled">
          </el-date-picker>
          <el-checkbox label="1" class="changqi" @change="licenseLong">长期</el-checkbox>
        </el-form-item>
      </div>
      <el-form-item label="商户地理位置" prop="region">
        <el-cascader :options="positionOptions" v-model="modelManageCity" clearable
                     filterable @change="positionChange($event,'geogPosition')"></el-cascader>
      </el-form-item>
      <el-form-item label="商户详细地址" prop="name">
        <el-input v-model="ruleForm.manageCityAddress" class="reInput"></el-input>
      </el-form-item>
      <el-form-item label="门头照片" prop="region">
        <el-upload
          :limit="1"
          action=""
          list-type="picture-card"
          :http-request="handleUpload"
          :file-list="doorPhotoTiy"
          :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'doorPhoto')})"
          :before-upload="beforeAvatarUpload"
          :on-remove="((file, fileList)=>{removeImg(file, fileList, 'doorPhoto')})"
          :on-preview="showImg"
          :show-file-list="true"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="收银台照片" prop="region">
        <el-upload
          :limit="1"
          action=""
          list-type="picture-card"
          :http-request="handleUpload"
          :file-list="checkstandPhotoTiy"
          :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'checkstandPhoto')})"
          :before-upload="beforeAvatarUpload"
          :on-remove="((file, fileList)=>{removeImg(file, fileList, 'checkstandPhoto')})"
          :on-preview="showImg"
          :show-file-list="true"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="店内环境照片" prop="region">
        <el-upload
          :limit="1"
          action=""
          list-type="picture-card"
          :http-request="handleUpload"
          :file-list="interiorPhotoTiy"
          :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'interiorPhoto')})"
          :before-upload="beforeAvatarUpload"
          :on-remove="((file, fileList)=>{removeImg(file, fileList, 'interiorPhoto')})"
          :on-preview="showImg"
          :show-file-list="true"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <!--------------------------------------录入经营信息结束-------------------------------------->
      <!--------------------------------------上传经营证件开始-------------------------------------->
      <el-divider content-position="left"><p class="fontStyle">上传经营证件</p></el-divider>
      <el-form-item label="法人身份证人像面照片" prop="region">
        <el-upload
          :limit="1"
          action=""
          list-type="picture-card"
          :http-request="handleUpload"
          :file-list="idCardStartTiy"
          :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'idCardStart')})"
          :before-upload="beforeAvatarUpload"
          :on-remove="((file, fileList)=>{removeImg(file, fileList, 'idCardStart')})"
          :on-preview="showImg"
          :show-file-list="true"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="法人身份证国徽面照片" prop="region">
        <el-upload
          :limit="1"
          action=""
          list-type="picture-card"
          :http-request="handleUpload"
          :file-list="idCardEndTiy"
          :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'idCardEnd')})"
          :before-upload="beforeAvatarUpload"
          :on-remove="((file, fileList)=>{removeImg(file, fileList, 'idCardEnd')})"
          :on-preview="showImg"
          :show-file-list="true"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="法人身份证姓名" prop="name">
        <el-input v-model="ruleForm.legalPerson" class="reInput"></el-input>
      </el-form-item>
      <el-form-item label="法人身份证号" prop="name">
        <el-input v-model="ruleForm.idCard" class="reInput"></el-input>
      </el-form-item>
      <el-form-item label="法人身份证起始有效期" prop="name">
        <el-date-picker v-model="ruleForm.idCardDateStart" value-format="yyyy-MM-dd"
                        type="date"  placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="法人身份证截至有效期" prop="name">
        <el-date-picker v-model="ruleForm.idCardDateEnd" value-format="yyyy-MM-dd"
                        type="date"  placeholder="选择日期" :disabled="idCardIsDisabled">
        </el-date-picker>
        <el-checkbox label="1" class="changqi" @change="idCardLong">长期</el-checkbox>
      </el-form-item>
      <el-form-item label="法人身份证居住地址" prop="name">
        <el-input v-model="ruleForm.idDocAddress" class="reInput"></el-input>
      </el-form-item>
      <el-form-item label="法人手持身份证照片" prop="region">
        <el-upload
          :limit="1"
          action=""
          list-type="picture-card"
          :http-request="handleUpload"
          :file-list="uncrpAuthHandPhotoTiy"
          :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'uncrpAuthHandPhoto')})"
          :before-upload="beforeAvatarUpload"
          :on-remove="((file, fileList)=>{removeImg(file, fileList, 'uncrpAuthHandPhoto')})"
          :on-preview="showImg"
          :show-file-list="true"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <!--------------------------------------上传经营证件结束-------------------------------------->
      <!--------------------------------------填写结算账号开始-------------------------------------->
      <el-divider content-position="left"><p class="fontStyle">填写结算账号</p></el-divider>

      <el-form-item label="结算账号类型" prop="resource">
        <el-radio-group v-model="ruleForm.settlementType" @change="settlementTypeSwitch" v-removeAriaHidden>
          <el-radio :label="1" label="1" :disabled="this.ruleForm.incomType === '1'">对私</el-radio>
          <el-radio :label="2" label="2" :disabled="this.ruleForm.incomType === '1'">对公</el-radio>
        </el-radio-group>
      </el-form-item>
      <!--商户类型为个人，只能选对私-->

      <!--对公填写信息-->
      <div v-if="corporateAccount">
        <el-form-item label="开户许可证照片" prop="region">
          <el-upload
            :limit="1"
            action=""
            list-type="picture-card"
            :http-request="handleUpload"
            :file-list="accountOpeningPermitTiy"
            :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'accountOpeningPermit')})"
            :before-upload="beforeAvatarUpload"
            :on-remove="((file, fileList)=>{removeImg(file, fileList, 'accountOpeningPermit')})"
            :on-preview="showImg"
            :show-file-list="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="对公账户名称" prop="name">
          <el-input v-model="ruleForm.settlementName" class="reInput"></el-input>
        </el-form-item>
        <el-form-item label="对公账号" prop="name">
          <el-input v-model="ruleForm.bankCard" class="reInput"></el-input>
        </el-form-item>
        <!--可搜索的下拉框-->
        <el-form-item label="开户银行" prop="name">
          <el-select v-model="ruleForm.brank" filterable placeholder="请选择" class="reInput">
            <el-option
              v-for="item in bankOptions"
              :key="item.value"
              :label="item.label"
              :value="item.label">
            <!--  :value="item.value"-->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户银行支行所在地区" prop="region">
          <el-cascader :options="positionOptions" v-model="modelOpeningCity" clearable
                       filterable @change="positionChange($event,'bankPosition')"></el-cascader>
        </el-form-item>
      </div>
      <!--对私填写信息-->
      <div v-if="privateAccount">
        <el-form-item label="结算银行卡照片" prop="region">
          <el-upload
            :limit="1"
            action=""
            list-type="picture-card"
            :http-request="handleUpload"
            :file-list="bankCardPhotoTiy"
            :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'bankCardPhoto')})"
            :before-upload="beforeAvatarUpload"
            :on-remove="((file, fileList)=>{removeImg(file, fileList, 'bankCardPhoto')})"
            :on-preview="showImg"
            :show-file-list="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="持卡人姓名" prop="name">
          <el-input v-model="ruleForm.settlementName" class="reInput"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" prop="name">
          <el-input v-model="ruleForm.bankCard" class="reInput"></el-input>
        </el-form-item>
      </div>
      <!--------------------------------------填写结算账号结束-------------------------------------->
      <!--------------------------------------填写企业受益人开始-------------------------------------->
      <!--<div v-if="beneficiary">
        <el-divider content-position="left"><p class="fontStyle">企业受益人信息</p></el-divider>
        <el-form-item label="受益人身份证人像面照片" prop="region">
          <el-upload
            :limit="1"
            action=""
            list-type="picture-card"
            :http-request="handleUpload"
            :file-list="beneficiaryIdDocCopyUrlTiy"
            :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'beneficiaryIdDocCopyUrl')})"
            :before-upload="beforeAvatarUpload"
            :on-remove="((file, fileList)=>{removeImg(file, fileList, 'beneficiaryIdDocCopyUrl')})"
            :on-preview="showImg"
            :show-file-list="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="受益人身份证国徽面照片" prop="region">
          <el-upload
            :limit="1"
            action=""
            list-type="picture-card"
            :http-request="handleUpload"
            :file-list="beneficiaryIdDocNationalUrlTiy"
            :on-success="((response, file, fileList)=>{afterSuccess(response, file, fileList, 'beneficiaryIdDocNationalUrl')})"
            :before-upload="beforeAvatarUpload"
            :on-remove="((file, fileList)=>{removeImg(file, fileList, 'beneficiaryIdDocNationalUrl')})"
            :on-preview="showImg"
            :show-file-list="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="受益人身份证姓名" prop="name">
          <el-input v-model="ruleForm.beneficiaryIdDocName" class="reInput"></el-input>
        </el-form-item>
        <el-form-item label="受益人身份证号" prop="name">
          <el-input v-model="ruleForm.beneficiaryIdDocNumber" class="reInput"></el-input>
        </el-form-item>
        <el-form-item label="受益人身份证起始有效期" prop="name">
          <el-date-picker v-model="ruleForm.beneficiaryDocPeriodBeginDate"
                          type="date"  placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="受益人身份证截至有效期" prop="name">
          <el-date-picker v-model="ruleForm.beneficiaryDocPeriodEndDate"
                          type="date"  placeholder="选择日期" :disabled="beneficiaryIsDisabled">
          </el-date-picker>
          <el-checkbox label="1" class="changqi" @change="beneficiaryLong">长期</el-checkbox>
        </el-form-item>
        <el-form-item label="受益身份证居住地址" prop="name">
          <el-input v-model="ruleForm.beneficiaryIdDocAddress" class="reInput"></el-input>
        </el-form-item>
      </div>-->


      <!--<el-form-item label="是否开通自动结算" prop="name">
        <el-checkbox v-model="ruleForm.name" label="1" class="changqi">开通</el-checkbox>
      </el-form-item>-->

      <el-divider content-position="left"><p class="fontStyle">费率设置</p></el-divider>
      <el-form-item prop="tempId" label="费率模板选择" class="dyIn">
        <el-select v-model="ruleForm.tempId" filterable placeholder="请选择费率模板" class="reInput">
          <el-option
            v-for="item in rateTempList"
            :key="item.id"
            :label="item.tempName"
            :value="item.id">
            <span style="float: left">{{ item.tempName }}  </span>
            <span style="float: right; color: #8492a6; font-size: 10px">微信费率：{{ item.wxRate }}%</span>
            <span style="float: right; color: #8492a6; font-size: 10px">支付宝费率：{{ item.aliRate }}%</span>
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item>
        <!--<el-button type="reference" @click="onSubmit">立即创建</el-button>-->
        <!--<el-button>取消</el-button>-->

        <el-popover
          placement="top"
          width="160"
          v-model="submitVisible">
          <p>确定提交进件资料吗？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="submitVisible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="onSubmit()">确定</el-button>
          </div>
          <el-button slot="reference">{{this.ruleForm.operation === 'audit' ? '发起进件' :
            this.ruleForm.operation === 'edit' ? '修改进件资料' : '保存进件'}}</el-button>
        </el-popover>
      </el-form-item>

    </el-form>
    <!--上传遮罩-->
    <!--<el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>-->

    <el-dialog :visible.sync="dialogVisible"  append-to-body :modal="false">
      <img width="100%" height="100%" :src="this.showImgUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {rules} from "vue-count-to/.eslintrc";
import axios from 'axios';
import common from "@/utils/oss";
import {addPiece, uploadFileOcr} from "@/api/piece/piece";
import {rateTempList} from "@/api/agent/agent";
import {getAgentTenantList} from "@/api/agent/agent";

export default {
  name: "shengfutongAddTenant",
  props:{
    formValue: {
      type: Object,
      default: {}
    }
  },
  computed: {
    rules() {
      return rules
    }
  },
  data() {
    return {

      timeoutId:null,

      // mcc model
      modelMccId:[],
      // 商户地理位置
      modelManageCity:[],
      // 开户支行所在地理位置
      modelOpeningCity:[],

      // dialogImageUrl: '',
      dialogVisible: false,
      // disabled: false,
      // options9: [],
      // value: '',
      // 保存确认框
      submitVisible:false,

      showImgUrl: '',
      hideUpload: false,
      // 营业执照缩略图地址
      licenseTiy:undefined,
      // 门头照缩略图地址
      doorPhotoTiy:undefined,
      // 收银台缩略图地址
      checkstandPhotoTiy:undefined,
      // 店内环境缩略图地址
      interiorPhotoTiy: undefined,
      // 法人身份证人像缩略图地址
      idCardStartTiy: undefined,
      // 法人身份证国徽面缩略图地址
      idCardEndTiy:undefined,
      // 法人手持身份证照片缩略图地址
      uncrpAuthHandPhotoTiy:undefined,
      // 开户许可证照片缩略图地址
      accountOpeningPermitTiy:undefined,
      // 受益人身份证人像面照片缩略图地址
      // beneficiaryIdDocCopyUrlTiy:undefined,
      // 受益人身份证人像面照片缩略图地址
      // beneficiaryIdDocNationalUrlTiy:undefined,
      // 结算银行卡照片缩略图地址
      bankCardPhotoTiy:undefined,
      // 费率模板集合
      rateTempList:[],
      // 代理下的所有通道
      channelOptions: [],



      // 营业执照长期按钮
      // licenseLongTime: false,
      licenseIsDisabled:false,
      // 法人身份证长期按钮
      // idCardLongTime: false,
      idCardIsDisabled:false,
      // 受益人身份证长期按钮
      // beneficiaryLongTime: false,
      // beneficiaryIsDisabled:false,


      // 行业mcc集合
      MCCoptions: [],
      // 位置集合
      positionOptions: [],
      // 银行集合
      bankOptions:[],

      // 显示与隐藏输入框------
      // 营业执照输入
      businessLicense: false,
      // 对公银行账户输入
      corporateAccount: false,
      // 对私账户输入
      privateAccount: true,
      // 受益人
      // beneficiary: false,

      // 显示与隐藏输入框------

      ruleForm: {
        id: undefined,
        // 保存、修改状态
        incomStatus:'',
        // 结算类型：对公对私
        settlementType: '1',
        // 平台商户号
        mchId: 0,
        // 平台商户名称
        mechName: '',
        //   通道 Tenantid
        tenantId: '',
        //   通道名称
        channelName: '',
        //   商户类型
        incomType: '',
      //   mccid
        mccId: '',
      //   联系人姓名
        userName:'',
      //   联系人电话
        phone:'',
      //   联系人邮箱
        contactEmail:'',
      //   商户简称
        mchFor:'',


      //   地理位置
        manageCity: '',
      //   商户详细地址
        manageCityAddress:'',
      //   营业执照图片地址
        license: '',
      //   营业执照名称
        registerUser:'',
      //   营业执照编号
        identifier:'',
      // 营业执照有效期开始时间
        licenseStartDate:'',
      //   营业执照有效期结束时间
        licenseEndDate:'',
        //门头照图片地址
        doorPhoto:'',
        // 收银台图片url
        checkstandPhoto:'',
      //   店内环境照片url
        interiorPhoto:'',
      // 法人身份证人像面
        idCardStart:'',
      //   法人身份证国徽面
        idCardEnd:'',
      //   法人姓名
        legalPerson:'',
      //   法人身份证号
        idCard:'',
      //   法人身份证起始有效期
        idCardDateStart:'',
      // 法人身份证截至有效期
        idCardDateEnd:'',
      //   法人身份证居住地址
        idDocAddress:'',
      //   法人手持身份证照片
        uncrpAuthHandPhoto:'',
      //   开户许可证照片
        accountOpeningPermit:'',
      //   对公账户名称、银行卡持卡人姓名
        settlementName:'',
      //   银行卡卡号
        bankCard:'',
      //   开户行名称
        brank:'',
      //   开户行城市
        openingCity:'',
      //   受益人身份证人像面照片
      //   beneficiaryIdDocCopyUrl:'',
        // 受益人身份证国徽面照片
        // beneficiaryIdDocNationalUrl:'',
        // 受益人身份证姓名
        // beneficiaryIdDocName:'',
        // 受益人身份证号
        // beneficiaryIdDocNumber:'',
        // 受益人身份证起始有效期
        // beneficiaryDocPeriodBeginDate:'',
        // 受益人身份证截至有效期
        // beneficiaryDocPeriodEndDate:'',
        // 受益身份证居住地址
        // beneficiaryIdDocAddress:'',
      //   结算银行卡照片url
        bankCardPhoto:'',
      //   费率模板
        tempId:'',
        remark: '',
        agentId: this.$store.getters.userInfo.id,

      //   页面状态
        operation:'',

      }
    }
  },
  created() {
    this.rateTemplateList();
//   加载地区
    this.getPositionOptions();
    //   加载开户行
    this.getBankOptions();

    // 加载行业集合
    this.getHangyeOptions();


  },
  mounted() {
    // this.ruleForm.mchId = this.$route.query.mchId;
    // this.ruleForm.mechName = this.$route.query.mechName;
    // this.ruleForm.tenantId = this.$route.query.tenantId;
    // this.ruleForm.channelName = this.$route.query.channelName;

    console.log(this.ruleForm.tenantId);
    // 页面数据回显
    this.initDataShengfutong();


  },
  methods: {
    // 编辑审核时、初始化页面数据
    initDataShengfutong(){
      // console.log('传递参数---：'+JSON.stringify(this.formValue) );
      if (JSON.stringify(this.formValue) !== "{}"){
        console.log('传递参数：'+JSON.stringify(this.formValue) );
        this.ruleForm = this.formValue
      }
      console.log('加载行业后执行');
      if('audit' === this.ruleForm.operation || 'edit' === this.ruleForm.operation){
      //   编辑 参数回显
      //   1、通道名回显
        this.queryChannelList();
        this.timeoutId = setTimeout(() => {
          //   行业mcc回显
          this.setMCCValues();
          //   地理位置回显
          this.setManageCityValues();
        //   开户行位置回显
          this.setOpeningCityValues();
        //   图片缩略图回显
          this.setPhotoValues();
          this.incomTypeSwitch();

        }, 100);
      }else{
        // 商户类型默认为个人
        this.ruleForm.incomType = '1';
        this.incomTypeSwitch();
      }
    },
    // 查询当前系统该代理已添加的所有通道
    queryChannelList(){
      getAgentTenantList(this.$store.getters.userInfo.id).then(res => {
        this.channelOptions = res.data;
        this.channelOptions.forEach(x =>{
          if(x.tenantId === this.ruleForm.tenantId){
            this.ruleForm.channelName = x.tenantName;
            console.log('通道名称-：' + this.ruleForm.channelName)
          }
        })
      })
      console.log('通道名称+：' + this.ruleForm.channelName)
    },
    // 根据选中的最后一级的多个 id 设置选中的值
    setMCCValues() {
      console.log('-------'+this.ruleForm.mccId);
      if(this.ruleForm.mccId){
        // console.log(this.MCCoptions);
        JSON.parse(JSON.stringify(this.MCCoptions)).forEach(m =>{
          // console.log(m.children)
          m.children.forEach(x =>{
            // console.log(x.value+"+" + this.ruleForm.mccId);
            if(x.value === this.ruleForm.mccId.toString()){
              // console.log(x.value + "," + m.value)
              this.modelMccId = [m.value, x.value];
            }
          });
        });
      }
    },
    setManageCityValues(){
      if(this.ruleForm.manageCity) {
        let c = this.ruleForm.manageCity.toString();
        // console.log(c);
        // console.log(c.substring(0,2)+","+ c.substring(2,4) + "," + c.substring(4,6));
        this.modelManageCity = [c.substring(0, 2) + '0000', c.substring(0, 4) + '00', c];
      }
    },
    setOpeningCityValues(){
      if(this.ruleForm.openingCity){
        let c = this.ruleForm.openingCity.toString();
        this.modelOpeningCity = [c.substring(0,2) + '0000',c.substring(0,4) + '00',c];
      }
    },
    // setBrankValues(){
    // },
    // handleRemove(file) {
    //   console.log(file);
    // },
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url;
    //   this.dialogVisible = true;
    // },
    // handleDownload(file) {
    //   console.log(file);
    // }
    // 商户类型切换
    incomTypeSwitch(){
      console.log("商户类型切换" + ",=="+this.ruleForm.incomType);
      this.ruleForm.incomType = this.ruleForm.incomType.toString();
      if(this.ruleForm.incomType === '0'){
        console.log("个体工商户");
      //   个体工商户
        this.businessLicense = true;
        // this.beneficiary = false;
        this.settlementTypeSwitch('1');
      }else if(this.ruleForm.incomType === '1'){
        console.log("个人");
      //   个人
        this.businessLicense = false;
        // this.beneficiary = false;
        this.ruleForm.settlementType = '1';
        this.settlementTypeSwitch('1');
      }else if(this.ruleForm.incomType === '2'){
        console.log("企业");
      //   企业
        this.businessLicense = true;
        // this.beneficiary = true;
        this.settlementTypeSwitch('2');
      }
    },
  //   结算类型切换
    settlementTypeSwitch(settlementType){
      console.log("结算类型切换" + settlementType);
      if(settlementType === '1'){
      //   对私
        console.log("对私");
        this.corporateAccount = false;
        this.privateAccount = true;
        this.ruleForm.settlementType = '1';
      }else if(settlementType === '2'){
      //   对公
        console.log("对公");
        this.corporateAccount = true;
        this.privateAccount = false;
        this.ruleForm.settlementType = '2';
      }
    },
    // 获取所有行业列表
    getHangyeOptions(){
      if(localStorage.getItem("sftMCCoptions")){
        this.MCCoptions = JSON.parse(localStorage.getItem("sftMCCoptions"));
        return;
      }
      axios.get('/sft/hangye.json').then(res=>{
        this.MCCoptions = res.data;
        localStorage.setItem("sftMCCoptions", JSON.stringify(res.data));
        // console.log('加载行业');
      })
    },
    // 获取所有地理位置
    getPositionOptions(){
      if(localStorage.getItem("sftPositionOptions")){
        this.positionOptions = JSON.parse(localStorage.getItem("sftPositionOptions"));
        return;
      }
      axios.get('/sft/diqu.json').then(res =>{
        this.positionOptions = res.data;
        localStorage.setItem("sftPositionOptions", JSON.stringify(res.data));
      })
    },
    // 获取所有银行
    getBankOptions(){
      if(localStorage.getItem("sftBankOptions")){
        this.bankOptions = JSON.parse(localStorage.getItem("sftBankOptions"));
        return;
      }
      axios.get('/sft/bank.json').then(res =>{
        this.bankOptions = res.data;
        localStorage.setItem("sftBankOptions", JSON.stringify(res.data));
      })
    },

    // 行业mcc选择赋值
    hangyeChange(value){
      this.ruleForm.mccId = value[1];
    },
    // 地理位置选择赋值
    positionChange(value, type){
      if('geogPosition' === type){
        this.ruleForm.manageCity = value[2];
      }else if ('bankPosition' === type){
        this.ruleForm.openingCity = value[2];
      }
    },
    // 表单提交
    onSubmit(){
      this.submitVisible = false;
      console.log(this.ruleForm);
      this.ruleForm.incomStatus = this.ruleForm.operation === 'audit' ? 1 : 0;
      // this.$refs['pieceForm'].validate((valid) => {
        if (this.ruleForm.registerUser){
          this.ruleForm.registerUser = this.ruleForm.registerUser.replace("(","（")
          this.ruleForm.registerUser = this.ruleForm.registerUser.replace(")","）")
        }
        // if (valid) {
          let removeEmptyValues1 = this.removeEmptyValues(JSON.parse(JSON.stringify(this.ruleForm)));
          removeEmptyValues1.remark = ''
          console.log(removeEmptyValues1)
          addPiece(removeEmptyValues1).then(res => {
            if (res.success) {
              this.$message.success("操作成功！");
            } else {
              this.$message.error(res.message);
            }
          }).catch(e=>{
            this.$message.error(e);
          });
        // } else {
        //   return false;
        // }
      // });
    },
    removeEmptyValues(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
          delete obj[key];
        } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
      return obj;
    },
    // 图片上传前的校验
    beforeAvatarUpload(file) {
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.warning("图片不能大于2M")
      }
      return isLt2M;
    },
    // 阿里云图片上传
    handleUpload(op) {
      return new Promise((resolve, reject) => {
        let bucketName = 'shizhi-image'; // 桶名
        let dir = `images`;
        this.loading = this.$loading({
          lock: true,
          text: '图片正在上传中请稍等....',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        common.bucketUpload(
          op.file,
          (res) => {
            let temp = {
              name: res.attachment,
              url: res.aliyunAddress
            };
            this.host = res.host;
            temp.url = temp.url.replaceAll("http://shizhi-image.oss-cn-beijing.aliyuncs.com/", "http://image.shizhipay.com/")
            resolve(temp); // 在上传成功时，使用 resolve 返回结果
          },
          (err) => {
            console.error("----------",err);
            reject(err); // 在上传失败时，使用 reject 返回错误信息
          },
          bucketName,
          dir
        );
      });
    },

    // --------------------------------------
    // // 营业执照上传成功之后处理
    // businessLicenseSuccess(response, file, fileList){
    //   //
    //   this.ruleForm.license = response.url;
    //   this.licenseTiy = [{url: response.url}];
    //   // this.fileSaveList(response, file, fileList);
    //   // ocr识别
    //   uploadFileOcr(response.url, 1).then(res => {
    //     this.ruleForm.registerUser = res.data.name
    //     this.ruleForm.identifier = res.data.reg_num
    //     this.ruleForm.licenseStartDate = this.transDate(res.data.establish_date)
    //     this.ruleForm.licenseEndDate = this.transDate(res.data.valid_period)
    //     this.ruleForm.registerAddress = res.data.address
    //     // this.pieceForm.aptitude = res.data.type == '个体工商户' ? 0 : 1
    //     // if (res.data.valid_period == "29991231") {
    //     //   this.cq = true;
    //     // }
    //     this.loading.close();
    //   }).catch(err => {
    //     this.loading.close();
    //     console.log(err);
    //     this.$message.error(err.message);
    //   });
    // },
    transDate(date) {
      let a = '';
      a = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
      return a;
    },
    // 删除照片
    removeImg(file, fileList, type){
      if('businessLicense' === type){
        this.ruleForm.license = '';
      }else if('doorPhoto' === type){
        this.ruleForm.doorPhoto = '';
      }else if('checkstandPhoto' === type){
        this.ruleForm.checkstandPhoto = '';
      }else if('interiorPhoto' === type){
        this.ruleForm.interiorPhoto = '';
      }else if("idCardStart" === type){
        this.ruleForm.idCardStart = '';
      }else if("idCardEnd" === type){
        this.ruleForm.idCardEnd = '';
      }else if("uncrpAuthHandPhoto" === type){
        this.ruleForm.uncrpAuthHandPhoto = '';
      }else if("accountOpeningPermit" === type){
        this.ruleForm.uncrpAuthHandPhoto = '';
      // }else if("beneficiaryIdDocCopyUrl" === type){
      //   this.ruleForm.beneficiaryIdDocCopyUrl = '';
      }else if("bankCardPhoto" === type){
        this.ruleForm.bankCardPhoto = '';
      }
    },
    //查看图片
    showImg(file, fileList) {
      this.showImgUrl = file.url;
      this.dialogVisible = true;
    },
    setPhotoValues(){
      if(this.ruleForm.license){
        this.licenseTiy = [{url: this.ruleForm.license}];
      }
      if(this.ruleForm.doorPhoto) {
        this.doorPhotoTiy = [{url: this.ruleForm.doorPhoto}];
      }
      if(this.ruleForm.checkstandPhoto) {
        this.checkstandPhotoTiy = [{url: this.ruleForm.checkstandPhoto}];
      }
      if(this.ruleForm.interiorPhoto) {
        this.interiorPhotoTiy = [{url: this.ruleForm.interiorPhoto}];
      }
      if(this.ruleForm.idCardStart){
        this.idCardStartTiy = [{url: this.ruleForm.idCardStart}];
      }
      if(this.ruleForm.idCardEnd){
        this.idCardEndTiy = [{url: this.ruleForm.idCardEnd}];
      }
      if(this.ruleForm.uncrpAuthHandPhoto){
        this.uncrpAuthHandPhotoTiy = [{url: this.ruleForm.uncrpAuthHandPhoto}];
      }
      if(this.ruleForm.accountOpeningPermit){
        this.accountOpeningPermitTiy = [{url: this.ruleForm.accountOpeningPermit}];
      }
      if(this.ruleForm.bankCardPhoto){
        this.bankCardPhotoTiy = [{url: this.ruleForm.bankCardPhoto}];
      }

    },

    // 图片上传之后的处理
    afterSuccess(response, file, fileList, type){
      if('businessLicense' === type){
        // 营业执照
        this.ruleForm.license = response.url;
        this.licenseTiy = [{url: response.url}];
        this.ocr(response.url, 1);
      }else if('doorPhoto' === type){
        // 门头照上传成功之后处理
        this.ruleForm.doorPhoto = response.url;
        this.doorPhotoTiy = [{url: response.url}];
      }else if('checkstandPhoto' === type){
        // 收银台照上传成功之后处理
        this.ruleForm.checkstandPhoto = response.url;
        this.checkstandPhotoTiy = [{url: response.url}];
      }else if('interiorPhoto' === type){
        // 店内照
        this.ruleForm.interiorPhoto = response.url;
        this.interiorPhotoTiy = [{url: response.url}];
      }else if("idCardStart" === type){
        // 法人身份证人像面
        this.ruleForm.idCardStart = response.url;
        this.idCardStartTiy = [{url: response.url}];
      //   ocr识别
        this.ocr(response.url, 2);
      }else if("idCardEnd" === type){
        // 法人身份证国徽面
        this.ruleForm.idCardEnd = response.url;
        this.idCardEndTiy = [{url: response.url}];
      //   ocr识别
        this.ocr(response.url, 3, 'legalPerson');
      }else if("uncrpAuthHandPhoto" === type){
        // 法人身份证国徽面
        this.ruleForm.uncrpAuthHandPhoto = response.url;
        this.uncrpAuthHandPhotoTiy = [{url: response.url}];
      }else if("accountOpeningPermit" === type){
        // 开户许可证照片
        this.ruleForm.accountOpeningPermit = response.url;
        this.accountOpeningPermitTiy = [{url: response.url}];
      // }else if("beneficiaryIdDocCopyUrl" === type){
      //   // 受益人身份证人像面照片
      //   this.ruleForm.beneficiaryIdDocCopyUrl = response.url;
      //   this.beneficiaryIdDocCopyUrlTiy = [{url: response.url}];
      //   this.ocr(response.url, 2, 'beneficiary');
      // }else if("beneficiaryIdDocNationalUrl" === type){
      //   // 受益人身份证国徽面照片
      //   this.ruleForm.beneficiaryIdDocNationalUrl = response.url;
      //   this.beneficiaryIdDocNationalUrlTiy = [{url: response.url}];
      //   this.ocr(response.url, 3, 'beneficiary');
      }else if("bankCardPhoto" === type){
        // 结算银行卡照片
        this.ruleForm.bankCardPhoto = response.url;
        this.bankCardPhotoTiy = [{url: response.url}];
        this.ocr(response.url, 7);
      }
      this.loading.close();
    },

    // ocr识别
    ocr(url, type){
      uploadFileOcr(url, type).then(res => {
        if(1 === type){
          // 营业执照
          this.ruleForm.registerUser = res.data.name
          this.ruleForm.identifier = res.data.reg_num
          this.ruleForm.licenseStartDate = this.transDate(res.data.establish_date)
          this.ruleForm.licenseEndDate = this.transDate(res.data.valid_period)
          this.ruleForm.manageCityAddress = res.data.address
          this.ruleForm.userName = res.data.person;

        }else if(2 === type){
          // 人面像
          // if('beneficiary' === idType){
          // //   受益人身份证
          //   this.ruleForm.beneficiaryIdDocName = res.data.name;
          //   this.ruleForm.beneficiaryIdDocNumber = res.data.num;
          // }else if('legalPerson' === idType) {
            //   身份证人像面
            this.ruleForm.legalPerson = res.data.name;
            this.ruleForm.idCard = res.data.num;
          // }
        }else if(3 === type){
          // 国徽面
          // if('beneficiary' === idType){
          //   //   受益人身份证
          //   this.ruleForm.beneficiaryDocPeriodBeginDate = this.transDate(res.data.start_date)
          //   this.ruleForm.beneficiaryDocPeriodEndDate = this.transDate(res.data.end_date)
          // }else if('legalPerson' === idType) {
            //   身份证人像面
            this.ruleForm.idCardDateStart = this.transDate(res.data.start_date)
            this.ruleForm.idCardDateEnd = this.transDate(res.data.end_date)
          // }
        }else if(7 === type){
          //   银行卡
          this.ruleForm.bankCard = res.data.card_num;
        }
        this.loading.close();
      }).catch(err => {
        this.loading.close();
        console.log(err);
        this.$message.error("OCR识别异常，请手动输入。"+err.message);
      });
    },

    // 下载图片
    // handleDownload(file) {
    //   console.log(file);
    // },
    changeImage(file, fileList) {
      this.hideUpload = fileList.length >= 1;
    },
    // fileSaveList(response, file, fileList) {
    //   if (response) {
    //     debugger
    //     if (response.success) {
    //       let attachmentInfo = response.data
    //       attachmentInfo.uid = file.uid;
    //       this.attachmentInfo.push(attachmentInfo);
    //     } else {
    //       this.$message.error(response.message);
    //     }
    //   }
    // },

    // // 门头照上传成功之后处理
    // doorPhotoSuccess(response, file, fileList){
    //   this.ruleForm.doorPhoto = response.url;
    //   this.doorPhotoTiy = [{url: response.url}];
    //   this.loading.close();
    // },
    // // 收银台照上传成功之后处理
    // checkstandPhotoSuccess(response, file, fileList){
    //   this.ruleForm.checkstandPhoto = response.url;
    //   this.checkstandPhotoTiy = [{url: response.url}];
    //   this.loading.close();
    // },
    // // 店内照上传成功之后处理
    // interiorPhotoSuccess(response, file, fileList){
    //   this.ruleForm.interiorPhoto = response.url;
    //   this.interiorPhotoTiy = [{url: response.url}];
    //   this.loading.close();
    // },

    // 营业执照长期按钮
    licenseLong(value){
      if(value){
        this.licenseIsDisabled = true;
        this.ruleForm.licenseEndDate = '2199-12-31';
      }else{
        this.licenseIsDisabled = false;
        this.ruleForm.licenseEndDate = '';
      }
    },
    // 身份证
    idCardLong(value){
      if(value){
        this.idCardIsDisabled = true;
        this.ruleForm.idCardDateEnd = '2199-12-31';
      }else{
        this.idCardIsDisabled = false;
        this.ruleForm.idCardDateEnd = '';
      }
    },
    // 受益人身份证
    // beneficiaryLong(value){
    //   if(value){
    //     this.beneficiaryIsDisabled = true;
    //     this.ruleForm.beneficiaryDocPeriodEndDate = '2199-12-31';
    //   }else{
    //     this.beneficiaryIsDisabled = false;
    //     this.ruleForm.beneficiaryDocPeriodEndDate = '';
    //   }
    // },
    backList() {
      // 页面跳转，根据不同的通道跳转到不同的页面
      // this.$router.push('/mch/inputPiece');
      this.$parent.changeType()
    },
    rateTemplateList() {
      let param = {
        pageNum: 1,
        pageSize: 100,
        agentId: this.$store.getters.userInfo.id
      }
      rateTempList(param).then(res => {
        if (res.success) {
          this.rateTempList = res.data.list;
        }
      })
    },



    //   ---------
  }
}

</script>


<style scoped>
.reInput {
  width: 220px;
}
.changqi {
  margin-left: 30px;
}
/deep/ .el-upload-list__item.is-ready {
  display: none;
}
::v-deep .el-upload-list__item.is-ready {
  display: none;
}
/* 推荐，实现简单 */
.el-upload-list__item.is-ready,
.el-upload-list__item.is-uploading {
  display: none !important;
}
.fontStyle{
  font-size: 150%;
  color:rgb(19, 90, 250);
}
</style>

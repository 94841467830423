import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API

/**
 * 代理商登录
 * @param data
 * @returns {*}
 */
export function adminLogin(data){
    return http({
        url: `${pro}/v1/user/agentLogin`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 发送短信验证码
 * @param query
 * @returns {*}
 */
export function sendSms(query){
    return http({
        url: `${pro}/v1/user/sendMessage`,
        method: 'get',
        params: query
    })
}

/**
 * 新增代理商
 * @param data
 * @returns {*}
 */
export function addAgent(data){
    return http({
        url: `${pro}/v1/user/addAgent`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 查询代理商列表
 * @param data
 * @returns {*}
 */
export function agentList(data){
    return http({
        url: `${pro}/v1/user/list`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 删除代理商
 * @param id
 * @returns {*}
 */
export function delAgent(id){
    return http({
        url: `${pro}/v1/user/delAgent?id=${id}`,
        method: 'post'
    })
}

/**
 * 删除代理商
 * @param id
 * @returns {*}
 */
export function aliPayCode(data){
    return http({
        url: `${pro}/v1/public/apliayCode`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * ocr识别
 * @param url
 * @param type
 * @returns {*}
 */
export function uploadFileOcr(url,type){
    return http({
        url: `${pro}/v1/upload?url=${url}&type=${type}`,
        method: 'post'
    })
}

/**
 * 获取类目
 * @param url
 * @param type
 * @returns {*}
 */
export function getMcc(type){
    return http({
        url: `${pro}/v1/public/getMcc?type=${type}`,
        method: 'get'
    })
}

/**
 * 获取所有银行
 * @param url
 * @param type
 * @returns {*}
 */
export function getBrankName(city){
    return http({
        url: `${pro}/v1/public/getBrankNameList?city=${city}`,
        method: 'get'
    })
}

/**
 * 根据省市地区代码获取所有银行名称
 * @returns {*}
 * @param proviceCode 省代码
 * @param cityCode  市代码
 */
export function getBrankNameByCode(proviceCode, cityCode){
  return http({
    url: `${pro}/v1/public/getBrankNameListByCode`,
    method: 'get',
    params:{
      cityCode: cityCode,
      proviceCode: proviceCode
    }
  })
}

/**
 * 根据省市地区代码和银行代码获取所有支行名称
 * @returns {*}
 * @param proviceCode 省代码
 * @param cityCode  市代码
 */
export function getSubBrankListByCode(proviceCode, cityCode, bankType){
  return http({
    url: `${pro}/v1/public/getSubBrankListByCode`,
    method: 'get',
    params:{
      cityCode: cityCode,
      proviceCode: proviceCode,
      bankType: bankType
    }
  })
}
/**
 * 获取指定城市的指定银行的支行
 * @param url
 * @param type
 * @returns {*}
 */
export function getSubBrankList(city,brankName){
    return http({
        url: `${pro}/v1/public/getSubBrankList?city=${city}&brankName=${brankName}`,
        method: 'get'
    })
}

/**
 * 获取代理商利润
 * @param url
 * @param type
 * @returns {*}
 */
export function getMoney(id){
    return http({
        url: `${pro}/v1/user/getMoney?id=${id}`,
        method: 'get'
    })
}

/**
 * 加载城市银行类目数据
 * @returns {*}
 */
export function initData(){
    return http({
        url: `${pro}/v1/app/initData`,
        method: 'GET'
    })
}

/**
 * 查询平台所有渠道
 * @returns {*}
 */
export function getTenantList(){
    return http({
        url: `${pro}/v1/channel/list`,
        method: 'get'
    })
}


/**
 * 查询安全发  记账本金额  签约人信息
 * @param no
 * @param id
 * @param channel
 * @returns {*}
 */
export function queryAgreement(no,id,channel){
  return http({
    url: `${pro}/v1/public/queryAgreement/${channel}/${no}/${id}`,
    method: 'get'
  })
}


/**
 * 记账本充值
 * @param data
 * @returns {*}
 */
export function bookPay(data){
  return http({
    url: `${pro}/v1/public/bookPay`,
    method: 'post',
    requestBody: true,
    data
  })
}

/**
 * 记账本转账-到支付宝
 * @param data
 * @returns {*}
 */
export function trans(data){
  return http({
    url: `${pro}/v1/public/trans`,
    method: 'post',
    requestBody: true,
    data
  })
}

/**
 * 记账本转账-到银行卡
 * @param data
 * @returns {*}
 */
export function transCard(data){
  return http({
    url: `${pro}/v1/public/transCard`,
    method: 'post',
    requestBody: true,
    data
  })
}

/**
 * 记账本转账-到银行卡
 * @param data
 * @returns {*}
 */
export function downloadPz(id,aggrentNo,channel){
  return http({
    url: `${pro}/v1/public/pz/${channel}/${id}/${aggrentNo}`,
    method: 'get'
  })
}

/**
 * 查询记账本转账历史记录列表
 * @param data
 */
export function queryHistoryTrans(data){
    return http({
        url: `${pro}/v1/public/queryHistoryTrans`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 查询记账本转账历史记录列表
 * @param data
 */
export function queryHistoryTransExport(data){
    return http({
        url: `${pro}/v1/public/queryHistoryTransExport`,
        method: 'post',
        requestBody: true,
        data
    })
}


/**
 * 签约安全发
 * @param no
 * @param id
 * @returns {*}
 */
export function pageSign(channel,id){
  return http({
    url: `${pro}/v1/public/pageSign/${channel}/${id}`,
    method: 'get'
  })
}

/**
 * 统计代付
 * @param data
 */
export function totalTransfer(id){
    return http({
        url: `${pro}/v1/public/totalTransfer/${id}`,
        method: 'post'
    })
}

export function kjTransfer(agentId,type){
  return http({
    url: `${pro}/v1/public/kjTransfer?agentId=${agentId}&type=${type}`,
    method: 'get'
  })
}

export function signList(data){
  return http({
    url: `${pro}/v1/public/signList`,
    method: 'post',
    requestBody: true,
    data
  })
}

export function addSign(data){
  return http({
    url: `${pro}/v1/public/aqf/addSign`,
    method: 'post',
    requestBody: true,
    data
  })
}
export function checkSign(id,channel,agreementNo){
  return http({
    url: `${pro}/v1/public/aqf/checkSign/${id}/${channel}/${agreementNo}`,
    method: 'post'
  })
}

export function bookList(id){
    return http({
        url: `${pro}/v1/public/aqf/bookList/${id}`,
        method: 'post'
    })
}

export function createBook(data){
    return http({
        url: `${pro}/v1/public/aqf/createBook`,
        method: 'post',
        requestBody: true,
        data
    })
}
export function queryChannel(channel,agentId){
  return http({
    url: `${pro}/v1/public/queryChannel/${channel}/${agentId}`,
    method: 'post'
  })
}

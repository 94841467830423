<template>
  <div style="line-height: 65px;display: flex;justify-content: space-between;">
    <div v-if="currentRoute.meta">
      <span style="font-size: 14px;color: #86909C;font-weight: bold">{{currentRoute.meta.upTitle}} / </span>
      <span style="font-size: 14px;color: #135AFA;font-weight: bold">{{currentRoute.meta.title}}</span>
    </div>
    <div v-else>
      <span style="font-size: 14px;color: #135AFA;font-weight: bold"></span>
    </div>
    <div style="margin-right: 40px;">
      <!--<span style="margin-left: -300px;font-size: 12px;color: blue;cursor: pointer" v-if="$store.getters.userInfo.userName === 'admin'" @click="qyaqf(1)">
        <i class="el-icon-full-screen"></i>
        签约安全发一
      </span>
      <span style="margin-left:20px;font-size: 12px;color: blue;cursor: pointer" v-if="$store.getters.userInfo.userName === 'admin'" @click="qyaqf(2)">
        <i class="el-icon-full-screen"></i>
        签约安全发二
      </span>-->
      <el-popover
        placement="bottom-end"
        width="250"
        trigger="hover">
        <div style="padding: 10px;">
          <div style="display: flex;justify-content: space-between;align-items: center;height: 10px;">
            <div style="display: flex;align-items: center;">
              <el-avatar size="small" src="https://img0.baidu.com/it/u=2580450068,3312521163&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"></el-avatar>
              <span style="margin-left: 5px;font-size: 12px;">{{ $store.getters.userInfo.name }}</span>
            </div>
            <span @click="out" style="font-size: 12px;color: #969799;cursor: pointer;">退出</span>
          </div>
        </div>
        <div slot="reference" style="display:flex;align-items: center;float:right; margin-right: 20px;cursor: pointer;">
          <el-avatar size="small" src="https://img0.baidu.com/it/u=2580450068,3312521163&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"></el-avatar>
          <span style="margin-left: 5px;font-size: 14px;">{{ $store.getters.userInfo.name }}</span>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {pageSign} from "@/api/user/user";
export default {
  name: "Header",
  data(){
    return{
      currentRoute: {}
    }
  },
  watch: {
    $route(e) {
      this.currentRoute = this.$route
      console.log(this.currentRoute)
    }
  },
  mounted() {
    this.currentRoute = this.$route
  },
  methods:{
    ...mapMutations(["logout"]),
    out(){
      this.logout();
      location.reload()
    },
    qyaqf(channel){
      pageSign(channel).then(res =>{
        window.open(res.data,"_blank","top=300,left=300,width=800,height=500,menubar=no,toolbar=no,status=no,scrollbars=yes")
      })
    }
  }
}
</script>

<style scoped>

</style>

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import * as filters from './filters'
import './assets/element-variables.scss'
import '../src/assets/font/font_4355151_67cd1pbrlwi/iconfont.css'
import '@/scss/index.scss'
import '@/scss/font-test.css'
import { Message,notification } from 'element-ui'
import VueClipboard from 'vue-clipboard2';
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$message = Message
Vue.prototype.$notification = notification
Object.keys(filters).filter(key =>{
  Vue.filter(key,filters[key])
})
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
Vue.use(VueClipboard);
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});
Vue.directive('trim', {
  bind(el, binding, vnode) {
    // 去除空格的函数   replace可修改扩展
    const trimValue = value => value.replace(/\s+/g, '')
    // 输入事件
    el.addEventListener('input', function(e) {
      e.target.value = trimValue(e.target.value)
      // 手动触发 input 事件以更新 v-model
      e.target.dispatchEvent(new Event('input'))
    })

    // 失去焦点事件
    el.addEventListener('blur', function(e) {
      e.target.value = trimValue(e.target.value)
      // 手动触发 change 事件以更新 v-model
      e.target.dispatchEvent(new Event('change'))
    })

    // 更改事件
    el.addEventListener('change', function(e) {
      e.target.value = trimValue(e.target.value)
      // 手动触发 input 事件以更新 v-model
      e.target.dispatchEvent(new Event('input'))
    })
  },

  unbind(el) {
    // 清理事件监听器
    el.removeEventListener('input', this)
    el.removeEventListener('blur', this)
    el.removeEventListener('change', this)
  }
});
// router.afterEach((to, from, next) => {
//   document.querySelector("body").setAttribute("style", "overflow: auto !important;")
// });


import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API


/**
 * 进件列表
 * @param data
 */
export function pieceList(data){
    return http({
        url: `${pro}/v1/piece/list`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 进件接口
 * @param data
 */
export function addPiece(data){
    return http({
        url: `${pro}/v1/piece/add`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * ocr识别
 * @param url
 * @param type
 * @returns {*}
 */
export function uploadFileOcr(url,type){
    return http({
        url: `${pro}/v1/upload?url=${url}&type=${type}`,
        method: 'post'
    })
}

/**
 * 获取类目
 * @param url
 * @param type
 * @returns {*}
 */
export function getMcc(type){
    return http({
        url: `${pro}/v1/public/getMcc?type=${type}`,
        method: 'get'
    })
}

/**
 * 获取所有银行
 * @param url
 * @param type
 * @returns {*}
 */
export function getBrankName(city){
    return http({
        url: `${pro}/v1/public/getBrankNameList?city=${city}`,
        method: 'get'
    })
}
/**
 * 获取指定城市的指定银行的支行
 * @param url
 * @param type
 * @returns {*}
 */
export function getSubBrankList(city,brankName){
    return http({
        url: `${pro}/v1/public/getSubBrankList?city=${city}&brankName=${brankName}`,
        method: 'get'
    })
}


/**
 * 查询报备状态
 * @param query
 * @returns {*}
 */
export function queryReportStatus(query){
    return http({
        url: `${pro}/v1/piece/queryReportStatus`,
        method: 'get',
        params: query
    })
}

/**
 * 查询e企通mcc
 * @param url
 * @param type
 * @returns {*}
 */
export function getEqtMcc(type){
  return http({
    url: `${pro}/v1/piece/queryEqtMcc/${type}`,
    method: 'post'
  })
}


/**
 * 直付通进件
 */
export function zftAddMch(data){
  return http({
    url: `${pro}/v1/zft/add`,
    method: 'post',
    data: data
  })
}
